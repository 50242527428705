(function ($) {
    "use strict";

    if ($(window).width() > 768 ) {

        $('.hero__cover-video').each(function () {
            var youtubeId = $(this).data('youtube-id');
            $(this).YTPlayer({
                fitToBackground: true,
                videoId: youtubeId,
                playerVars: {
                    modestbranding: 0,
                    autoplay: 1,
                    showinfo: 0,
                    branding: 0,
                    rel: 0
                }                               
            });
        })
    }

})(jQuery); // End of use strict