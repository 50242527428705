$(function () {

    var wysiwygTable = $('.wysiwyg table');

    if (wysiwygTable.length > 0) {
        wysiwygTable.wrap('<div class="wysiwyg-table"></div>');
    }

    $('#calendar').on('click', '.cal-month-dayn', function (e) {

        if (e.target.tagName === 'SPAN' && e.target.classList.contains('tag')) {
            var dateRef = findAncestor(e.target, 'cal-month-dayn');
            var calDate = (dateRef.dataset.calDate);
            var eventDate = $('#events-listing').find('time[datetime="' + calDate + '"]');

            if (eventDate) {

                $([document.documentElement, document.body]).animate({
                    scrollTop: eventDate.offset().top - headerOffset()
                }, 2000);
            }
        }
    });

    function headerOffset() {
        var offset = 100;
        if (window.innerWidth > 768) {
            offset = 200;
        } else {
            offset = 100;
        }
        return offset;
    }

    function findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

}());
