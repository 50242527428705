if ( $('#interactiveMaps').length ) {

    var map;
    var markerGroup = [];
    var markers = [];
    var boxList = [];
    var manualStateChange = false;
    var districts = [];
    var schoolCorridors = [];
    var dynamicCategories = [];
    var espritLibreCoordinates = [];
    var cyclePathsCoordinates = [];

    function initMap() {
        map = new google.maps.Map(document.getElementById('interactiveMaps'), {
            center: { lat: 45.396066, lng: -73.565756 },
            zoom: 14,
            scrollwheel: true,
            navigationControl: true,
            scaleControl: true,
            draggable: true,
            disableDoubleClickZoom: false,
            streetViewControl: true,
        });
        var thePanorama = map.getStreetView();
        var $sideMenu = $('.map__section-group');
        google.maps.event.addListener(thePanorama, 'visible_changed', function() {
            if (thePanorama.getVisible()) {
                // Display your street view visible UI
                $sideMenu.addClass('hidden');
            } else {
                // Display your original UI
                $sideMenu.removeClass('hidden');
            }
        });
    }

    function reloadPin(isChangeHash, refreshSelected, forceUnselect) {
        var $form = $('#form-sections');

        $.ajax({
            url: $form.attr('action'),
            dataType: 'json',
            type: 'POST',
            async: true,
            data: $form.serialize()
        }).done(function(data) {
            if (data.success) {
                var results = data.result.markers;

                // Esprit Libre
                if (!data.result.showEspritLibre && espritLibreCoordinates.length > 0) {
                    espritLibreCoordinates[0].setMap(null);
                    espritLibreCoordinates = [];
                }

                // Cycle Paths
                if (!data.result.showCyclePaths && cyclePathsCoordinates.length > 0) {
                    $.each(cyclePathsCoordinates, function(index, value){
                        if (cyclePathsCoordinates[index] != undefined) {
                            cyclePathsCoordinates[index].setMap(null);
                        }
                    });
                    cyclePathsCoordinates = [];
                }
                
                // Cycle Paths
                if (data.result.showCyclePaths && cyclePathsCoordinates.length === 0) {
                    var cyclePathsLatLng = [
                        [   { "lat": 45.40926703472899, "lng": -73.59972953796387 },
                            { "lat": 45.40999010683616, "lng": -73.59702587127686 },
                            { "lat": 45.41005036242728, "lng": -73.59179019927979 },
                            { "lat": 45.40999010683616, "lng": -73.59041690826416 },
                            { "lat": 45.41035163941855, "lng": -73.58865737915039 },
                            { "lat": 45.41059265985449, "lng": -73.5860824584961 },
                            { "lat": 45.41017087341663, "lng": -73.58290672302246 },
                            { "lat": 45.41002023463975, "lng": -73.58088970184326 },
                            { "lat": 45.41002023463975, "lng": -73.5780143737793 },
                            { "lat": 45.41002023463975, "lng": -73.57754230499268 },
                            { "lat": 45.4091465218112, "lng": -73.57707023620605 },
                            { "lat": 45.40947793171644, "lng": -73.57569694519043 },
                            { "lat": 45.408875366805994, "lng": -73.57462406158447 },
                            { "lat": 45.40842343890466, "lng": -73.56728553771973 },
                            { "lat": 45.408784981515005, "lng": -73.56520414352417 },
                            { "lat": 45.409041072797166, "lng": -73.55786561965942 },
                            { "lat": 45.40913145767838, "lng": -73.55587005615234 },
                            { "lat": 45.40905613695408, "lng": -73.55526924133301 },
                            { "lat": 45.409071201106975, "lng": -73.55262994766235 },
                            { "lat": 45.409041072797166, "lng": -73.54370355606079 } ],

                        [   { "lat": 45.40019771933747, "lng": -73.60127449035645 },
                            { "lat": 45.400770244092804, "lng": -73.58681201934814 },
                            { "lat": 45.40098117280339, "lng": -73.57943058013916 },
                            { "lat": 45.40049904887923, "lng": -73.57638359069824 },
                            { "lat": 45.399866254985376, "lng": -73.57367992401123 },
                            { "lat": 45.396762448974705, "lng": -73.56818675994873 },
                            { "lat": 45.39522554987977, "lng": -73.56346607208252 },
                            { "lat": 45.39402010957649, "lng": -73.55831623077393 },
                            { "lat": 45.393944768703776, "lng": -73.55774760246277 },
                            { "lat": 45.393929700517184, "lng": -73.5575920343399 },
                            { "lat": 45.393929700517184, "lng": -73.55741500854492 },
                            { "lat": 45.394012575493775, "lng": -73.55686783790588 },
                            { "lat": 45.394084149239355, "lng": -73.55603635311127 },
                            { "lat": 45.39414253828045, "lng": -73.55437606573105 },
                            { "lat": 45.39417173277838, "lng": -73.55239793658257 },
                            { "lat": 45.394148188829575, "lng": -73.55167508125305 },
                            { "lat": 45.39343244810571, "lng": -73.54859054088593 } ],

                        [   { "lat": 45.39284478052407, "lng": -73.58035326004028 },
                            { "lat": 45.39961012210873, "lng": -73.57674837112427 },
                            { "lat": 45.40501879915963, "lng": -73.5739803314209 },
                            { "lat": 45.40545568918731, "lng": -73.57348680496216 },
                            { "lat": 45.40563647027938, "lng": -73.57303619384766 },
                            { "lat": 45.405772055718785, "lng": -73.57219934463501 },
                            { "lat": 45.40581725079294, "lng": -73.57131958007812 },
                            { "lat": 45.40593777081391, "lng": -73.56863737106323 },
                            { "lat": 45.405787120747526, "lng": -73.56769323348999 },
                            { "lat": 45.40541049382388, "lng": -73.56689929962158 },
                            { "lat": 45.40498866868832, "lng": -73.5660195350647 },
                            { "lat": 45.404566840402964, "lng": -73.56531143188477 },
                            { "lat": 45.4038135677733, "lng": -73.56295108795166 },
                            { "lat": 45.40349719027369, "lng": -73.56151342391968 },
                            { "lat": 45.4031958767221, "lng": -73.56056928634644 },
                            { "lat": 45.402653508279414, "lng": -73.55842351913452 },
                            { "lat": 45.402487783550065, "lng": -73.557608127594 },
                            { "lat": 45.40242751989156, "lng": -73.55640649795532 },
                            { "lat": 45.402322058334555, "lng": -73.5555911064148 },
                            { "lat": 45.40220153059975, "lng": -73.55490446090698 },
                            { "lat": 45.4019152761991, "lng": -73.55411052703857 },
                            { "lat": 45.401327696832745, "lng": -73.55278015136719 },
                            { "lat": 45.40092090753787, "lng": -73.5513424873352 },
                            { "lat": 45.4007853104554, "lng": -73.55029106140137 },
                            { "lat": 45.400740111355574, "lng": -73.54930400848389 },
                            { "lat": 45.40070997860228, "lng": -73.54878902435303 },
                            { "lat": 45.40083050951904, "lng": -73.54793071746826 },
                            { "lat": 45.40101130541205, "lng": -73.54645013809204 },
                            { "lat": 45.40120716697665, "lng": -73.54441165924072 },
                            { "lat": 45.40128249816683, "lng": -73.54351043701172 },
                            { "lat": 45.40128249816683, "lng": -73.54284524917603 },
                            { "lat": 45.40126743193683, "lng": -73.54252338409424 } ],

                        [   { "lat": 45.39243793015705, "lng": -73.57921600341797 },
                            { "lat": 45.39715420088217, "lng": -73.5767912864685 },
                            { "lat": 45.39882664963137, "lng": -73.57593297958374 },
                            { "lat": 45.40039358372242, "lng": -73.57511758804321 },
                            { "lat": 45.40532010298857, "lng": -73.57260704040527 },
                            { "lat": 45.40569673051485, "lng": -73.5724139213562 } ],

                        [   { "lat": 45.38882135372485, "lng": -73.56917381286621 },
                            { "lat": 45.39163928943777, "lng": -73.56775760650635 },
                            { "lat": 45.39501459968285, "lng": -73.5660195350647 },
                            { "lat": 45.39576798962741, "lng": -73.56563329696655 },
                            { "lat": 45.39802809919647, "lng": -73.564453125 },
                            { "lat": 45.400227852363955, "lng": -73.5633373260498 },
                            { "lat": 45.403482124634266, "lng": -73.56170654296875 } ],

                        [   { "lat": 45.399843655072374, "lng": -73.58153343200684 },
                            { "lat": 45.3994669885249, "lng": -73.58130812644958 },
                            { "lat": 45.39918448696642, "lng": -73.58111500740051 },
                            { "lat": 45.39907148594752, "lng": -73.58101308345795 },
                            { "lat": 45.39893211771302, "lng": -73.58095943927765 },
                            { "lat": 45.398653380212686, "lng": -73.58087360858917 },
                            { "lat": 45.3985516783499, "lng": -73.58062148094177 },
                            { "lat": 45.398088367547565, "lng": -73.57924818992615 },
                            { "lat": 45.397884961605044, "lng": -73.57847034931183 },
                            { "lat": 45.39785106054344, "lng": -73.57834160327911 },
                            { "lat": 45.39768532172724, "lng": -73.5781055688858 },
                            { "lat": 45.39759115172873, "lng": -73.57788562774658 },
                            { "lat": 45.39749133135899, "lng": -73.57742428779602 },
                            { "lat": 45.397472497307184, "lng": -73.5772955417633 },
                            { "lat": 45.39734819240783, "lng": -73.57681274414062 },
                            { "lat": 45.397238954543255, "lng": -73.57640236616135 },
                            { "lat": 45.39714666721726, "lng": -73.57602149248123 },
                            { "lat": 45.397082631024915, "lng": -73.57568621635437 },
                            { "lat": 45.39705061290156, "lng": -73.57527583837509 },
                            { "lat": 45.3970430792228, "lng": -73.57502102851868 },
                            { "lat": 45.39706944709394, "lng": -73.57494860887527 },
                            { "lat": 45.39706191341773, "lng": -73.57470989227295 },
                            { "lat": 45.396986576600334, "lng": -73.57439339160919 },
                            { "lat": 45.396905589409634, "lng": -73.57400983572006 },
                            { "lat": 45.39698280975683, "lng": -73.57396960258484 },
                            { "lat": 45.39688110488724, "lng": -73.5735297203064 },
                            { "lat": 45.3965063020283, "lng": -73.57187747955322 },
                            { "lat": 45.39631042416818, "lng": -73.57091188430786 },
                            { "lat": 45.396291589722765, "lng": -73.57076168060303 },
                            { "lat": 45.39624262013534, "lng": -73.57064366340637 },
                            { "lat": 45.39618234981562, "lng": -73.57061684131622 },
                            { "lat": 45.396012839196764, "lng": -73.57063293457031 },
                            { "lat": 45.395941267893924, "lng": -73.57052564620972 },
                            { "lat": 45.39584709498891, "lng": -73.57019305229187 },
                            { "lat": 45.39576422270266, "lng": -73.5698390007019 },
                            { "lat": 45.39564368097846, "lng": -73.56917917728424 },
                            { "lat": 45.39548170263168, "lng": -73.56831550598145 },
                            { "lat": 45.395402596758686, "lng": -73.56787025928497 },
                            { "lat": 45.395323490774956, "lng": -73.56749475002289 },
                            { "lat": 45.395210482034685, "lng": -73.56695830821991 },
                            { "lat": 45.39499199782932, "lng": -73.56604099273682 },
                            { "lat": 45.394750910829345, "lng": -73.56510758399963 },
                            { "lat": 45.39451735681727, "lng": -73.5640561580658 },
                            { "lat": 45.39432900610446, "lng": -73.56327295303345 },
                            { "lat": 45.39411051849117, "lng": -73.56237173080444 },
                            { "lat": 45.39386942773064, "lng": -73.5613203048706 },
                            { "lat": 45.393575596975644, "lng": -73.56016159057617 },
                            { "lat": 45.3943742103328, "lng": -73.55979681015015 },
                            { "lat": 45.394023876617496, "lng": -73.55831623077393 },
                            { "lat": 45.39393723461099, "lng": -73.55771541595459 },
                            { "lat": 45.39322525830962, "lng": -73.55752766132355 },
                            { "lat": 45.393172518967525, "lng": -73.55744183063507 },
                            { "lat": 45.39309340986207, "lng": -73.55742037296295 },
                            { "lat": 45.39302183486175, "lng": -73.55749547481537 },
                            { "lat": 45.392543410163086, "lng": -73.55551600456238 },
                            { "lat": 45.392377655779526, "lng": -73.55475425720215 },
                            { "lat": 45.392121488957834, "lng": -73.55360627174377 },
                            { "lat": 45.39185778660503, "lng": -73.55246901512146 },
                            { "lat": 45.39159408302181, "lng": -73.55136394500732 },
                            { "lat": 45.39138311926935, "lng": -73.55046272277832 },
                            { "lat": 45.39117215472946, "lng": -73.54962587356567 } ],

                        [   { "lat": 45.38662869158725, "lng": -73.55169653892517 },
                            { "lat": 45.38778154517041, "lng": -73.55117082595825 },
                            { "lat": 45.38863298402867, "lng": -73.55085968971252 },
                            { "lat": 45.390433772638495, "lng": -73.54992628097534 },
                            { "lat": 45.391028999771734, "lng": -73.5495400428772 },
                            { "lat": 45.39192559589899, "lng": -73.5490894317627 },
                            { "lat": 45.39345505058296, "lng": -73.54832768440247 },
                            { "lat": 45.39646863326177, "lng": -73.54682564735413 },
                            { "lat": 45.39819383700727, "lng": -73.54593515396118 },
                            { "lat": 45.39954985538077, "lng": -73.54528069496155 },
                            { "lat": 45.40010732016155, "lng": -73.5448944568634 },
                            { "lat": 45.401229766344244, "lng": -73.54431509971619 } ],

                        [   { "lat": 45.393982439152694, "lng": -73.55076313018799 },
                            { "lat": 45.39625015392079, "lng": -73.54969024658203 },
                            { "lat": 45.396257687705216, "lng": -73.54946494102478 },
                            { "lat": 45.39615974842915, "lng": -73.54929327964783 },
                            { "lat": 45.3962124849835, "lng": -73.5490357875824 },
                            { "lat": 45.39616728222564, "lng": -73.5488748550415 },
                            { "lat": 45.3962124849835, "lng": -73.54845643043518 },
                            { "lat": 45.39628782283293, "lng": -73.5481345653534 },
                            { "lat": 45.398480110262675, "lng": -73.54699730873108 },
                            { "lat": 45.39852531117043, "lng": -73.54692220687866 },
                            { "lat": 45.39860064593631, "lng": -73.54695439338684 },
                            { "lat": 45.399723122050375, "lng": -73.5464072227478 },
                            { "lat": 45.399790921906884, "lng": -73.54657888412476 },
                            { "lat": 45.40004705396395, "lng": -73.54761958122253 },
                            { "lat": 45.400898308046735, "lng": -73.54725480079651 } ],

                        [   { "lat": 45.39417832508223, "lng": -73.55390667915344 },
                            { "lat": 45.39516527847529, "lng": -73.55389595031738 },
                            { "lat": 45.39582072654738, "lng": -73.55268359184265 },
                            { "lat": 45.39655150451495, "lng": -73.55203986167908 },
                            { "lat": 45.398314373291605, "lng": -73.55119228363037 },
                            { "lat": 45.39886431682596, "lng": -73.55091333389282 },
                            { "lat": 45.3994669885249, "lng": -73.55087041854858 },
                            { "lat": 45.39983612176602, "lng": -73.55081677436829 },
                            { "lat": 45.4007853104554, "lng": -73.55035543441772 } ],

                        [   { "lat": 45.39890951742636, "lng": -73.55865955352783 },
                            { "lat": 45.399278654309505, "lng": -73.55686783790588 },
                            { "lat": 45.39933138795307, "lng": -73.5560417175293 },
                            { "lat": 45.399278654309505, "lng": -73.55526924133301 },
                            { "lat": 45.3990451190106, "lng": -73.55408906936646 },
                            { "lat": 45.39883418307226, "lng": -73.55323076248169 },
                            { "lat": 45.398306839781334, "lng": -73.55118155479431 } ],

                        [   { "lat": 45.40843097106594, "lng": -73.56724798679352 },
                            { "lat": 45.4055846840882, "lng": -73.56726542115211 } ],

                        [   { "lat": 45.39357653874339, "lng": -73.56016762554646 },
                            { "lat": 45.39285749455088, "lng": -73.56052301824093 } ],

                        [   { "lat": 45.402487783550065, "lng": -73.55753570795059 },
                            { "lat": 45.40214314988578, "lng": -73.55767518281937 },
                            { "lat": 45.40204710406378, "lng": -73.55776101350784 },
                            { "lat": 45.401858778448634, "lng": -73.55801582336426 },
                            { "lat": 45.401779681503065, "lng": -73.55807483196259 },
                            { "lat": 45.401710000768816, "lng": -73.55812311172485 },
                            { "lat": 45.399841771745876, "lng": -73.55905383825302 },
                            { "lat": 45.399723122050375, "lng": -73.55909138917923 },
                            { "lat": 45.39957622208219, "lng": -73.55912357568741 },
                            { "lat": 45.399482055235, "lng": -73.5591396689415 },
                            { "lat": 45.39935022138528, "lng": -73.55912894010544 },
                            { "lat": 45.39928242099996, "lng": -73.5591208934784 },
                            { "lat": 45.395112540943636, "lng": -73.55728089809418 },
                            { "lat": 45.39442883206044, "lng": -73.55696707963943 },
                            { "lat": 45.39395606984111, "lng": -73.55672836303711 },
                            { "lat": 45.392392724379945, "lng": -73.55607390403748 },
                            { "lat": 45.3915695961981, "lng": -73.5557171702385 },
                            { "lat": 45.39141890781853, "lng": -73.55569571256638 },
                            { "lat": 45.39122112871068, "lng": -73.55573058128357 },
                            { "lat": 45.39108739196935, "lng": -73.55577349662781 },
                            { "lat": 45.3884879389348, "lng": -73.5570877790451 },
                            { "lat": 45.38793977814089, "lng": -73.55735063552856 },
                            { "lat": 45.38708079383396, "lng": -73.55779722332954 } ],

                        [   { "lat": 45.40247083440262, "lng": -73.5572500526905 },
                            { "lat": 45.40231546698071, "lng": -73.55730101466179 },
                            { "lat": 45.402223187946035, "lng": -73.55734527111053 },
                            { "lat": 45.402126200634925, "lng": -73.55742037296295 },
                            { "lat": 45.40200755573666, "lng": -73.55755180120468 },
                            { "lat": 45.401835237702606, "lng": -73.55783075094223 },
                            { "lat": 45.401714708929234, "lng": -73.55794474482536 },
                            { "lat": 45.40166009424419, "lng": -73.55798229575157 },
                            { "lat": 45.39964684711453, "lng": -73.55895593762398 },
                            { "lat": 45.399579047085155, "lng": -73.55897068977356 },
                            { "lat": 45.39946793019437, "lng": -73.5589773952961 },
                            { "lat": 45.399418963359594, "lng": -73.5589787364006 },
                            { "lat": 45.39936246311288, "lng": -73.55896934866905 },
                            { "lat": 45.399280537654754, "lng": -73.55894789099693 },
                            { "lat": 45.39554197369868, "lng": -73.5572862625122 },
                            { "lat": 45.39403517773899, "lng": -73.55660900473595 },
                            { "lat": 45.39396642921497, "lng": -73.5565821826458 },
                            { "lat": 45.39266866741453, "lng": -73.55604037642479 },
                            { "lat": 45.39236917969004, "lng": -73.55590090155602 },
                            { "lat": 45.39192559589899, "lng": -73.55569303035736 },
                            { "lat": 45.39174947992512, "lng": -73.5556273162365 },
                            { "lat": 45.39158278141218, "lng": -73.55557635426521 },
                            { "lat": 45.39144339470755, "lng": -73.55556428432465 },
                            { "lat": 45.39134073806337, "lng": -73.55556830763817 },
                            { "lat": 45.39123243039262, "lng": -73.5555911064148 },
                            { "lat": 45.391077032067706, "lng": -73.55564475059509 },
                            { "lat": 45.39086418275957, "lng": -73.55575740337372 },
                            { "lat": 45.390803906703646, "lng": -73.5557895898819 },
                            { "lat": 45.38962097609671, "lng": -73.55637967586517 },
                            { "lat": 45.38956446605322, "lng": -73.55640381574631 },
                            { "lat": 45.38826660317163, "lng": -73.5570502281189 },
                            { "lat": 45.387758940424185, "lng": -73.5572862625122 },
                            { "lat": 45.387444356767595, "lng": -73.55749815702438 },
                            { "lat": 45.38707325882611, "lng": -73.5576805472374 } ]
                    ];

                    var lineColor = data.result.cyclePathsColor;
                    
                    for (var i = 0; i < cyclePathsLatLng.length; i++) {
                        var cyclePath = new google.maps.Polyline({
                            path: cyclePathsLatLng[i],
                            strokeColor: lineColor,
                            strokeWeight: 3
                        });
                        cyclePath.setMap(map);
                        cyclePathsCoordinates.push(cyclePath);  // Cycle Path Information
                    }
                }

                // Districts
                var selectedDistricts = data.result.districts;
                for (var i = 0; i < districts.length; i++) {
                    if (districts[i] !== undefined) {
                        districts[i].setMap(null);
                    }
                }
                districts = [];

                $.each(selectedDistricts, function(index, value){
                    if (districts[value.id] != undefined) {
                        districts[value.id].setMap(null);
                    }

                    var district = new google.maps.Polygon({
                        paths: value.coordinates,
                        strokeColor: value.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                        fillColor: value.color,
                        fillOpacity: 0.35
                    });
                    district.setMap(map);
                    districts[value.id] = district; // District Area Information
                    boxList[value.id] = value; // District Infobox Information

                    google.maps.event.addListener(district, 'click', (function() {
                        return function() {
                            var $infoBox = fillInfoBox(boxList[value.id]);

                            if ($("#selected-marker").val() == value.id) {
                                $infoBox.toggleClass('is-open');
                            } else {
                                $infoBox.addClass('is-open');
                            }

                            $("#selected-marker").val(value.id);
                            manualStateChange = false;
                            changeHash(data.result.url, value.id);
                        }
                    })(district, district.id));
                });

                // School Corridors
                var selectedSchoolCorridors = data.result.schoolCorridors;
                $.each(schoolCorridors, function(key, value){
                    if (value !== undefined) {
                        $.each(value, function(index, schoolCorridor) {
                           schoolCorridor.setMap(null);
                        });
                    }
                });
                schoolCorridors = [];

                $.each(selectedSchoolCorridors, function(index, value){
                    if (schoolCorridors[value.id] != undefined) {
                        schoolCorridors[value.id].setMap(null);
                    }
                    var coordinates = value.coordinates;
                    var lineColor = value.color;
                    schoolCorridors[value.id] = [];
                    boxList[value.id] = value; // School Corridor Infobox Information

                    for (var i = 0; i < coordinates.length; i++) {
                        var schoolCorridor = new google.maps.Polyline({
                            path: coordinates[i],
                            strokeColor: lineColor,
                            strokeWeight: 3
                        });
                        schoolCorridor.setMap(map);
                        schoolCorridors[value.id].push(schoolCorridor);  // School Corridor Area Information

                        google.maps.event.addListener(schoolCorridor, 'click', (function() {
                            return function() {
                                var $infoBox = fillInfoBox(boxList[value.id]);

                                if ($("#selected-marker").val() == value.id) {
                                    $infoBox.toggleClass('is-open');
                                } else {
                                    $infoBox.addClass('is-open');
                                }

                                $("#selected-marker").val(value.id);
                                manualStateChange = false;
                                changeHash(data.result.url, value.id);
                            }
                        })(schoolCorridor, schoolCorridor.id));
                    }
                });

                // Dynamic categories
                var selectedDynamicCategories = data.result.dynamicCategories;
                $.each(dynamicCategories, function(key, value){
                    if (value !== undefined) {
                        $.each(value, function(index, dynamicCategory) {
                            dynamicCategory.setMap(null);
                        });
                    }
                });
                dynamicCategories = [];

                $.each(selectedDynamicCategories, function(index, value){
                    if (dynamicCategories[value.id] != undefined) {
                        dynamicCategories[value.id].setMap(null);
                    }
                    var coordinates = value.coordinates;
                    dynamicCategories[value.id] = [];
                    boxList[value.id] = value; // Dynamic Category Infobox Information

                    for (var i = 0; i < coordinates.length; i++) {
                        var lineColor = coordinates[i].color;

                        if (coordinates[i].type === 'polygon') {
                            var dynamicCategory = new google.maps.Polygon({
                                paths: coordinates[i].coordinates,
                                strokeColor: lineColor,
                                strokeOpacity: 0.8,
                                strokeWeight: 3,
                                fillColor: lineColor,
                                fillOpacity: 0.35
                            });
                        } else {
                            var dynamicCategory = new google.maps.Polyline({
                                path: coordinates[i].coordinates,
                                strokeColor: lineColor,
                                strokeWeight: 3
                            });
                        }

                        dynamicCategory.setMap(map);
                        dynamicCategories[value.id].push(dynamicCategory);  // Dynamic Category Area Information

                        google.maps.event.addListener(dynamicCategory, 'click', (function() {
                            return function() {
                                var $infoBox = fillInfoBox(boxList[value.id]);

                                if ($("#selected-marker").val() == value.id) {
                                    $infoBox.toggleClass('is-open');
                                } else {
                                    $infoBox.addClass('is-open');
                                }

                                $("#selected-marker").val(value.id);
                                manualStateChange = false;
                                changeHash(data.result.url, value.id);
                            }
                        })(dynamicCategory, dynamicCategory.id));
                    }
                });

                // Markers
                for (markerID in results) {
                    var theMarkerData = results[markerID];
                    var categoryID = theMarkerData.category_id;
                    var categoryColor = theMarkerData.category_color;
                    var myLatLng = new google.maps.LatLng(theMarkerData.lat, theMarkerData.long);

                    var icons = {
                        path: 'M30,15c0-8.284-6.716-15-15-15S0,6.716,0,15c0,6.88,4.638,12.664,10.954,14.433l4.546,7.576l4.793-7.988C25.961,26.879,30,21.418,30,15z',
                        fillColor: categoryColor,
                        fillOpacity: 1,
                        scale: 1,
                        strokeColor: categoryColor,
                        strokeWeight: 1,
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(15, 37),
                    };

                    var marker = new MarkerWithLabel({
                        position: myLatLng,
                        map: map,
                        title: theMarkerData.title,
                        labelContent: '<span class="fc '+theMarkerData.icon+' map__pin"></span>',
                        labelAnchor: new google.maps.Point(0, 37), // TODO: A voir ce que nous pouvons faire pour que ce soit fait en CSS
                        icon: icons

                      });

                    if (markerGroup[categoryID] === undefined) {
                        markerGroup[categoryID] = [];
                    }

                    markerGroup[categoryID].push(marker);
                    markers[theMarkerData.id] = marker;

                    boxList[theMarkerData.id] = theMarkerData;

                    google.maps.event.addListener(marker,'click',(function(marker, markerID) {
                        return function() {
                            var markerData = boxList[markerID];
                            var $infoBox = fillInfoBox(markerData);
                            if ($("#selected-marker").val() == markerID) {
                                $infoBox.toggleClass('is-open');
                            } else {
                                $infoBox.addClass('is-open');
                            }

                            //closeAllInfoBox(markerID);
                            //boxList[markerID].open(map, this);
                            $("#selected-marker").val(markerID);
                            manualStateChange = false;
                            changeHash(data.result.url, markerID);
                        }
                    })(marker, theMarkerData.id));

                    // Esprit libre
                    if (theMarkerData.isEspritLibre && espritLibreCoordinates.length === 0) {
                        var espritLibre = new google.maps.Polygon({
                            paths: [{ "lat": 45.40366291204207, "lng": -73.57966661453247 },
                                    { "lat": 45.40278910087822, "lng": -73.58039617538452 },
                                    { "lat": 45.402141266635944, "lng": -73.57964515686035 },
                                    { "lat": 45.40129756439282, "lng": -73.57998847961426 },
                                    { "lat": 45.401493424965025, "lng": -73.57730627059937 },
                                    { "lat": 45.40131263061478, "lng": -73.57664108276367 },
                                    { "lat": 45.40548581940949, "lng": -73.57434511184692 },
                                    { "lat": 45.40574192564928, "lng": -73.5723602771759 },
                                    { "lat": 45.40586244583091, "lng": -73.57026278972626 },
                                    { "lat": 45.40591517332955, "lng": -73.56851130723953 },
                                    { "lat": 45.40571179556368, "lng": -73.56768250465393 },
                                    { "lat": 45.40538789612861, "lng": -73.56686979532242 },
                                    { "lat": 45.40498866868832, "lng": -73.56620728969574 },
                                    { "lat": 45.40461956911089, "lng": -73.56541603803635 },
                                    { "lat": 45.40439735493698, "lng": -73.56481656432152 },
                                    { "lat": 45.404175139889034, "lng": -73.56424927711487 },
                                    { "lat": 45.40334653369878, "lng": -73.56102526187897 },
                                    { "lat": 45.40283429833887, "lng": -73.5592845082283 },
                                    { "lat": 45.40257817891728, "lng": -73.55817809700966 },
                                    { "lat": 45.402435052852404, "lng": -73.5572862625122 },
                                    { "lat": 45.40225426151537, "lng": -73.55534434318542 },
                                    { "lat": 45.40105650429491, "lng": -73.55183601379395 },
                                    { "lat": 45.400838042692826, "lng": -73.55076313018799 },
                                    { "lat": 45.40072504498095, "lng": -73.54980826377869 },
                                    { "lat": 45.40083050951908, "lng": -73.54736745357513 },
                                    { "lat": 45.401222233222725, "lng": -73.54496955871582 },
                                    { "lat": 45.40120716697665, "lng": -73.54423999786377 },
                                    { "lat": 45.40126743193683, "lng": -73.54226589202881 },
                                    { "lat": 45.40190021013783, "lng": -73.54239463806152 },
                                    { "lat": 45.4020056724823, "lng": -73.54248046875 },
                                    { "lat": 45.40144069333932, "lng": -73.54466915130615 },
                                    { "lat": 45.401293797836715, "lng": -73.54605317115784 },
                                    { "lat": 45.401139368819585, "lng": -73.54748010635376 },
                                    { "lat": 45.4010037722614, "lng": -73.54992628097534 },
                                    { "lat": 45.40148965842197, "lng": -73.55226516723633 },
                                    { "lat": 45.40249719974087, "lng": -73.55477571487427 },
                                    { "lat": 45.402721304619504, "lng": -73.55449676513672 },
                                    { "lat": 45.402894561563436, "lng": -73.55494737625122 },
                                    { "lat": 45.402608310674196, "lng": -73.55505466461182 },
                                    { "lat": 45.40280416670246, "lng": -73.55584859848022 },
                                    { "lat": 45.4026685741398, "lng": -73.55651378631592 },
                                    { "lat": 45.402819232522674, "lng": -73.55732917785645 },
                                    { "lat": 45.40310548234324, "lng": -73.5579514503479 },
                                    { "lat": 45.40321094243784, "lng": -73.5595178604126 },
                                    { "lat": 45.40411487803045, "lng": -73.56305837631226 },
                                    { "lat": 45.40470242840962, "lng": -73.56312274932861 },
                                    { "lat": 45.40504892961483, "lng": -73.56374502182007 },
                                    { "lat": 45.40565153534428, "lng": -73.56471061706543 },
                                    { "lat": 45.40581725079294, "lng": -73.5659122467041 },
                                    { "lat": 45.40626919954572, "lng": -73.56677055358887 },
                                    { "lat": 45.40626919954572, "lng": -73.56810092926025 },
                                    { "lat": 45.406118550363225, "lng": -73.56986045837402 },
                                    { "lat": 45.406118550363225, "lng": -73.57101917266846 },
                                    { "lat": 45.406193875004696, "lng": -73.5719633102417 },
                                    { "lat": 45.40607335553013, "lng": -73.57243537902832 },
                                    { "lat": 45.406028160660874, "lng": -73.57591152191162 },
                                    { "lat": 45.40593777081391, "lng": -73.57619047164917 },
                                    { "lat": 45.40545568918731, "lng": -73.57616901397705 },
                                    { "lat": 45.40298495627977, "lng": -73.57728481292725 },
                                    { "lat": 45.40310548234324, "lng": -73.57885122299194 },
                                    { "lat": 45.40357251841049, "lng": -73.57865810394287 }],
                            strokeColor: theMarkerData.category_color,
                            strokeOpacity: 0.8,
                            strokeWeight: 3,
                            fillColor: theMarkerData.category_color,
                            fillOpacity: 0.35
                        });
                        espritLibre.setMap(map);
                        espritLibreCoordinates[0] = espritLibre; // Esprit libre Area Information
                    }
                }

                var selected = data.result.selected;

                if (selected && refreshSelected) {
                    $("#selected-marker").val(selected);
                    var markerData = boxList[selected];
                    fillInfoBox(markerData);
                    $('.map__infobox').addClass('is-open');
                    //boxList[selected].open(map, markers[selected]);
                }

                if (isChangeHash && !forceUnselect) {
                    changeHash(data.result.url, selected);
                }

                if (forceUnselect) {
                    changeHash(data.result.url, null);
                }
            } else {
                for (var i = 0; i < districts.length; i++) {
                    if (districts[i] !== undefined) {
                        districts[i].setMap(null);
                    }
                }
                districts = [];

                $.each(schoolCorridors, function(key, value){
                    if (value !== undefined) {
                        $.each(value, function(index, schoolCorridor) {
                           schoolCorridor.setMap(null);
                        });
                    }
                });
                schoolCorridors = [];

                $.each(dynamicCategories, function(key, value){
                    if (value !== undefined) {
                        $.each(value, function(index, dynamicCategory) {
                            dynamicCategory.setMap(null);
                        });
                    }
                });
                dynamicCategories = [];

                if (espritLibreCoordinates.length > 0) {
                    espritLibreCoordinates[0].setMap(null);
                    espritLibreCoordinates = [];
                }

                // Cycle Paths
                if (cyclePathsCoordinates.length > 0) {
                    $.each(cyclePathsCoordinates, function(index, value){
                        if (cyclePathsCoordinates[index] != undefined) {
                            cyclePathsCoordinates[index].setMap(null);
                        }
                    });
                    cyclePathsCoordinates = [];
                }
            }
        });
    }

    function fillInfoBox(markerData){
        var $infoBox = $('.map__infobox');
        $infoBox.find('.map__infobox__title').text(markerData.title);
        $infoBox.find('.map__infobox__location').text(markerData.address);
        var $image = $infoBox.find('.map__infobox__image');
        if (markerData.image) {
            $image.prop('src', markerData.image);
            $image.removeClass('is-hidden');
        } else {
            $image.prop('src', '');
            $image.addClass('is-hidden');
        }
        $infoBox.find('.map__infobox__description').html(markerData.description);
        return $infoBox;
    }

    // Sets the map on all markers in the array.
    function removeMarkerGroup(group) {
        if (group !== undefined) {
            for (var i = 0; i < group.length; i++) {
                group[i].setMap(null);
            }
        }
    }

    function changeHash(query, selected) {
        var infoBoxOpened = $('.map__infobox').hasClass('is-open');

        var selectedQuery = "";
        if (selected && infoBoxOpened) {
            $("#selected-marker").val(selected);
            selectedQuery = "&selected="+selected;
        } else {
            selectedQuery = "";
        }
        History.pushState({'q':query, 'selected':selected}, null, "?q="+query+selectedQuery);
    }

    function closeAllInfoBox(selected) {
        for (key in boxList) {
            if(markers[key] !== markers[selected] || selected == null) {
                //boxList[key].close();
                $('.map__infobox').removeClass('is-open');
            }
        }
    }

    // Bind to StateChange Event
    History.Adapter.bind(window,'popstate',function(event){ // Note: We are using statechange instead of popstate
        if (manualStateChange == true) {
            var state = History.getState(); // Note: We are using History.getState() instead of event.state

            var stateData = state.data;

            if (Object.keys(stateData).length == 0) {
                closeAllInfoBox(0);

                $(".js-show-pin").each(function() {
                    document.getElementById($(this).attr('for')).checked = false;

                    var categoryID = document.getElementById($(this).attr('for')).value;
                    removeMarkerGroup(markerGroup[categoryID]);
                    markerGroup[categoryID] = [];
                });

                $(".js-check-all").each(function() {
                    document.getElementById($(this).attr('for')).checked = false;
                });

                $("#selected-marker").val("");
                // reloadPin(false);
            }
            else {
                closeAllInfoBox(stateData['selected']);

                if (stateData['selected']) {
                    $("#selected-marker").val(stateData['selected']);
                }
                else {
                    $("#selected-marker").val("");
                }

                $.ajax({
                    url: $("#hash-url-ajax").val(),
                    dataType: 'json',
                    type: 'POST',
                    async: false,
                    data: {'hashURL': stateData['q']}
                }).done(function(data) {
                    if (data.success) {
                        var categories = data.categories;

                        $(".js-show-pin").each(function() {
                            document.getElementById($(this).attr('for')).checked = false;

                            var categoryID = document.getElementById($(this).attr('for')).value;
                            removeMarkerGroup(markerGroup[categoryID]);
                            markerGroup[categoryID] = [];
                        });

                        for (key in categories) {
                            document.getElementById('categoryChild-'+categories[key]).checked = true;
                        };

                        verifyCheckAll();
                        reloadPin(false, false, false);
                    }
                });
            }
        }
        manualStateChange = true;
    });

    function verifyCheckAll() {
        $(".js-check-all").each(function() {
            var numberOfChecked = $(this).closest('ul').find('input.js-checkbox-category:checkbox:checked').length;
            var totalCheckboxes = $(this).closest('ul').find('input.js-checkbox-category:checkbox').length;

            if (numberOfChecked == totalCheckboxes) {
                $(this).closest('ul').find('input.js-checkbox-check').prop('checked', true);
                $(this).closest('ul').find('.js-check-all').text(document.getElementById('checkbox-remove-all-label').value);
            }
            else {
                $(this).closest('ul').find('input.js-checkbox-check').prop('checked', false);
                $(this).closest('ul').find('.js-check-all').text(document.getElementById('checkbox-show-all-label').value);
            }
        });
    }

    $(document).ready(function () {

        if ($("#is-old-search").val()) {
            reloadPin(false, true, false);
            verifyCheckAll();
        }

        $('.js-map-trigger').click(function() {
            $(this).parents('.map__section')
                .toggleClass('is-open')
                .siblings('.is-open').removeClass('is-open');
        });

        if ( $(window).outerWidth() < 768 ) {
            $('.map__mobile-trigger').on('click', function() {
                $('.map__section-group').toggleClass('is-open');
            });
        }

        $('.map__infobox__close').on('click', function() {
            $('.map__infobox').removeClass('is-open');
        });

        $(document).keyup(function(e) {
            if (e.keyCode == 27) {
                $('.map__infobox').removeClass('is-open');
            }
        });

        $(".js-check-all").click(function(e) {

            if (document.getElementById($(this).attr('for')).checked) {
                $(this).closest("ul").find('label.js-show-pin').each(function(item) {
                    document.getElementById($(this).attr('for')).checked = false;

                    var categoryID = document.getElementById($(this).attr('for')).value;
                    removeMarkerGroup(markerGroup[categoryID]);
                    markerGroup[categoryID] = [];
                });

                $(this).text(document.getElementById('checkbox-show-all-label').value);
            }
            else {
                $(this).closest("ul").find('label.js-show-pin').each(function(item) {
                    document.getElementById($(this).attr('for')).checked = true;
                });

                $(this).text(document.getElementById('checkbox-remove-all-label').value);
            }

            manualStateChange = false;
            reloadPin(true, false, false);
        });

        $(".js-show-pin").click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            document.getElementById($(this).attr('for')).checked = !document.getElementById($(this).attr('for')).checked;

            verifyCheckAll();

            if (!document.getElementById($(this).attr('for')).checked) {
                var categoryID = document.getElementById($(this).attr('for')).value;
                removeMarkerGroup(markerGroup[categoryID]);
                markerGroup[categoryID] = [];
            }

            closeAllInfoBox(null);
            manualStateChange = false;
            reloadPin(true, false, true);
        });
    });
}
