
$(function () {

    var calendar_filter_form = $('#filter-form'),

        calendar_filter_trigger = $('[calendar-filter-trigger]'),
        calendar_filter_target = $('[calendar-filter-target]'),

        calendar_filter_tag_remove = $('[calendar-filter-tag-remove]'),
        calendar_filter_tag_input = $('[calendar-filter-tag-input]'),

        // calendar_alltag_trigger = $('[calendar-alltag-trigger]'),
        calendar_reset_trigger = $('[calendar-reset-trigger]'),
        calendar_submit_trigger = $('[calendar-submit-trigger]');

    // Toggle the menu
    if (calendar_filter_trigger && calendar_filter_target) {
        calendar_filter_trigger.click(function (e) {
            e.preventDefault();

            if (e.target == this) {
                calendar_filter_target.toggleClass('is-active');
            }
        });
    }

    // Helper function to uncheck a filter

    function disableFilter(el) {
        // Uncheck the input 
        el.parent('.js-tag-label').find('input').removeAttr('checked');

        // Remove the active class of the tag ( round circle )
        el.parent('.js-tag-label').find('.tag').removeClass('is-active');

        // Remove the active class of self ( the x )
        el.parent('.js-tag-label').find('[calendar-filter-tag-remove]').removeClass('is-active');
    }

    // Helper function to check a filter

    function enableFilter(el) {
        // Check the input 
        el.parent('.js-tag-label').find('input').attr('checked', 'checked');

        // Add the active class of the tag ( round circle )
        el.parent('.js-tag-label').find('.tag').addClass('is-active');

        // Add the active class of self ( the x )
        el.parent('.js-tag-label').find('[calendar-filter-tag-remove]').addClass('is-active');
    }

    /* Uncheck a value from the filter list when clicking remove */

    if (calendar_filter_tag_remove && calendar_filter_form) {
        calendar_filter_tag_remove.click(function (e) {
            var el = $(this);
            disableFilter(el);
        });
    }

    /* Check a value from the filter list when clicking a filter */

    if (calendar_filter_tag_input && calendar_filter_form) {
        calendar_filter_tag_input.click(function (e) {
            var el = $(this);
            enableFilter(el);
        });
    }

    /* Check all value from the filter list */

    // if (calendar_alltag_trigger && calendar_filter_form) {
    //     calendar_alltag_trigger.click(function (e) {
    //         e.preventDefault;

    //         // Get all filters from parent

    //         var els = $(this).parents('.filters-wrapper').find('.js-tag-label');

    //         // Loop in each to change status
    //         els.each(function (index) {

    //             var input = $(this).find('input'),
    //                 remove = $(this).find('[calendar-filter-tag-remove]'),
    //                 tag = $(this).find('.tag');


    //             // If the input is not checked, check it
    //             if (!input.is(':checked')) {
    //                 input.attr('checked', 'checked');
    //             }

    //             // If the remove has not class is-active, add it
    //             if (!remove.hasClass('is-active')) {
    //                 remove.addClass('is-active');
    //             }

    //             // If the tag has not class is-active, add it

    //             if (!tag.hasClass('is-active')) {
    //                 tag.addClass('is-active');
    //             }
    //         });
    //     });
    // }

    /* Uncheck all value from the filter list */

    if (calendar_reset_trigger && calendar_filter_form) {
        calendar_reset_trigger.click(function (e) {
            e.preventDefault;

            // Get all filters from parent

            var els = $(this).parents('.filters-wrapper').find('.js-tag-label');

            // Loop in each to change status
            els.each(function (index) {

                var input = $(this).find('input'),
                    remove = $(this).find('[calendar-filter-tag-remove]'),
                    tag = $(this).find('.tag');


                // If the input is checked, uncheck it
                if (input.is(':checked')) {
                    input.removeAttr('checked');
                }

                // If the remove has class is-active, remove it
                if (remove.hasClass('is-active')) {
                    remove.removeClass('is-active');
                }

                // If the tag has class is-active, remove it

                if (tag.hasClass('is-active')) {
                    tag.removeClass('is-active');
                }
            });
        });
    }

    /* Submit the form when clicking apply filters */

    if (calendar_submit_trigger && calendar_filter_form) {
        calendar_submit_trigger.click(function (e) {
            calendar_filter_form.submit();
        });
    }

}(jQuery));