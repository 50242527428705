(function($) {
    "use strict";
    var Equalizer = function() {

        //  Object clone
        var _this = this;

        //  Set Object's options
        _this.settings = {
            widths  : false
        };

        var $items; //grab all items
        var dims = []; //create empty array to store values
        var tallest; //create variable to make note of the tallest slide

        _this.init = function(item,settings) {
            _this.settings = $.extend(_this.settings, settings);
            var selector = String(item.data('target'));
            if(item.data('target')!=undefined) {
                $items = $(selector, item);
            } else {
                $items = item.children();
            }
            _this.equalize(item,$items,_this.settings);
        }

        _this.equalize = function(item,$items,settings) {
            $items.each(function() { //add heights to array
                dims.push($(this).outerHeight(false)); 
                if(settings.widths) { dims.push($(this).outerWidth()); }
            });
            tallest = Math.max.apply(null, dims); //cache largest value
            
            $items.each(function() {
                if( $(window).width()>item.data('watch') ) {
                    $(this).css('min-height',tallest + 'px');
                }
            });
        }

       
        

    }

    $.fn.equalizeDims = function(options) {

        var len = this.length;

        //  Enable multiple instance support
        return this.each(function(index) {
            //  Cache a copy of $(this)
            var item = $(this),
                key = 'equalizer' + (len > 1 ? '-' + ++index : ''),
                instance = (new Equalizer).init(item, options);

            //  Invoke an Equalizer instance
            item.data(key, instance).data('key', key);
        });
    };
  
}(jQuery));

$(document).ready(function(){
    $('[data-watch]').equalizeDims();
});
$(window).on('resize orientationchange', function () {
    $('[data-watch]').equalizeDims();
});