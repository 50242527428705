(function(d, s, id){
   var js, fjs = d.getElementsByTagName(s)[0];
   if (d.getElementById(id)) {return;}
   js = d.createElement(s); js.id = id; js.async=1;
   js.src = "//connect.facebook.net/fr_CA/sdk.js";
   fjs.parentNode.insertBefore(js, fjs);
 }(document, 'script', 'facebook-jssdk'));


function shareTwitter(title, url) {
    window.open('http://twitter.com/share'
            + '?text=' + encodeURIComponent(title)
            + '&link=' + encodeURIComponent(url), 'sharer', 'toolbar=0,status=0,width=580,height=325');
}