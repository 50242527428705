/*!
 * javascript Objectfit IE emulator
 * Author: Globalia
 * Licensed under the WTFPL license
 *
 * Replace all object-fit:cover images with a background cover
 */


window.emulateObjectfit = function (node) {
    // restrict to valid object-fit value
    var objectFit = node.currentStyle['object-fit'];

    if (!objectFit || !/^(contain|cover|fill)$/.test(objectFit)) return;

    var addEventListener = node.addEventListener || node.attachEvent;
    var removeEventListener = node.removeEventListener || node.detachEvent;
    var on = node.addEventListener ? '' : 'on';
    var img = node.nodeName.toLowerCase() === 'img';
    var type = img ? 'load' : 'loadedmetadata';

    addEventListener.call(node, on + type, onload);

    if (node.complete) onload();

    function onload() {
        removeEventListener.call(node, on + type, onload);
        // var imgx = document.createElement('objectfit');
        // imgx.appendChild(node.parentNode.replaceChild(imgx, node));
        // imgx.style.backgroundImage = 'url('+ node.src +')';

        node.parentNode.classList.add('objectfit');
        node.parentNode.style.backgroundImage = 'url('+ node.src +')';
    }
};

window.emulateObjectfit.init = function () {
    if (document.body) {
        var all = document.querySelectorAll('img,video');
        var index = -1;

        while (all[++index]) window.emulateObjectfit(all[index]);
    } else {
        setTimeout(window.emulateObjectfit.init);
    }
};

if (/MSIE|Trident/.test(navigator.userAgent)) {
    window.emulateObjectfit.init();
}



/* -------------------- */


// Mobile and touch booleans

function is_mobile() {
    return window.innerWidth < 1024 ? true : false; 
}
function is_phone() {
    return window.innerWidth < 640 ? true : false; 
}

var isTouch = ('ontouchstart' in window) || (window.navigator.msMaxTouchPoints),
    isMobile = is_mobile(),
    isPhone = is_phone();

(function($) {
    'use strict';

    // Update booleans on resize
    $(window).resize(function() {
        isMobile = is_mobile(),
        isPhone = is_phone();
    });

    // Selectors

    var wrapNav  = $('#nav'),
        topNav   = $('#topNav'),
        mainNav  = $('#mainNav'),
        pageWrap = $('#page'),
        alert    = $('#alert'),
        submenu = $('[gr-submenu]');

    // JS Anchors
    $('a[href^="#"].anchor').on('click', function (event) {
        event.preventDefault();

        var $anchor = $(this);
        if ($($anchor.attr('href')).offset() == null) {
            return;
        }

        var topPosition = $($anchor.attr('href')).offset().top;

        if ($anchor.attr('href') !== '#fold') {
            if ($(window).outerWidth() > 1024) {
                if ( $('.header--home').length !== 0 ) {
                    topPosition -= ( topNav.outerHeight() + mainNav.outerHeight() - 20 );
                } else {
                    topPosition -= ( mainNav.outerHeight() + 20 );
                }
            } else {
                topPosition -= topNav.outerHeight();
            }
        }

        $('html, body').stop().animate({
            scrollTop: topPosition,
        }, 480, function() {
            if ($anchor.hasClass('newsletter')) {
                if ($('#footer')) {
                    $('#footer').find('#inscription').focus();
                }
            }
        });
        
        return false;
    });

    // Bootstrap select

    $('.dropdown--select').selectpicker({
        dropupAuto: false,
    });

    // Nav menus

    $('.navbar__dropdown').click(function(e) {
        var menu = $(this).next(),
            open = true;

        e.stopPropagation();

        if ( !menu.hasClass('is-open') ) open = false;
        $('.navbar__menu').removeClass('is-open');
        $('.navbar__dropdown').removeClass('is-open');
        $('body').removeClass('menu-open');

        if ( !open ) {
            $(this).addClass('is-open');
            menu.addClass('is-open');
            $('body').addClass('has-nav-opened');
        }
    });

    $(document).on('click', '.has-nav-opened', function() {
        $('.navbar__dropdown').each(function() {
            if ( $(this).hasClass('is-open') ) {
                $(this).trigger('click');
            }
        });
    });

    $('.navbar__toggle').click(function(e) {
        $(this).toggleClass('is-active');
        $('body').toggleClass('has-mobilenav-opened');
    });

    // Alerts

    if ( $('body').hasClass('has-alert') ) {
        setTimeout(function(){
            pageWrap.css('top',alert.outerHeight() + 'px');

            if (isMobile && wrapNav.length) {
                wrapNav.css('top',alert.outerHeight() +'px');

                if (alert.length) {
                    $(window).scroll(function() {
                        $('.alert__close').click();
                        pageWrap.css('padding-top', wrapNavH + gr_submenu.outerHeight() + 'px');
                    });
                }

                if (submenu.length > 0) {
                    pageWrap.css('top', alert.outerHeight() + 10 + 'px');
                    submenu.css('min-height', $('#topNav').outerHeight());
                    submenu.css('top', submenu.outerHeight() + alert.outerHeight());
                }
            }
        }, 300);
    }

    $('.alert__close').click(function(e) {
        e.preventDefault();

        var navTop = $('#nav');

        if (isMobile && wrapNav.length) {
            wrapNav.css('top', 0);
            pageWrap.css('top', 0);
            alert.remove();

            if (submenu.length > 0 && navTop.length > 0) {
                submenu.css('top', navTop.outerHeight());
            }

        } else {
            pageWrap.css({
                'top' : '0',
                'transition' : 'top .3s',
            });

            alert.css('top','-'+ alert.outerHeight() +'px');
        }
    });

    // Sticky Navigation

    var stickPos = 0;
    var gr_submenu = $('[gr-submenu]');

    if(wrapNav.length) {
        if ( !isMobile) {
            var wrapNavH = $('#nav').outerHeight();
    
            if ( $('.navbar__group--home').length !== 0 ) {
                stickPos = mainNav.offset().top + mainNav.outerHeight();
            } else {
                var main = $('#main');
    
                stickPos = topNav.offset().top + wrapNav.outerHeight();
            }
        } else {
            var wrapNavH = $('#topNav').outerHeight();
    
            if ( $('.navbar__group--home').length !== 0 ) {
                var header = $('header');
            } else {
                var main = $('#main');
            }
        }
    }

    function stickyHeader() {
        if ( $(document).scrollTop() > stickPos ) {
            if ( !wrapNav.hasClass('is-fixed') ) {
                wrapNav.addClass('is-fixed');

                if ( $('.navbar__group--home').length == 0 ) {
                    main.css('margin-top', wrapNavH);
                }
            }
        } else {
            if ( wrapNav.hasClass('is-fixed') ) {
                if ( $('.navbar__group--home').length !== 0 || ( $('.navbar__group--home').length == 0 && $(document).scrollTop() < topNav.height() ) ) {
                    wrapNav.removeClass('is-fixed');

                    if ( $('.navbar__group--home').length == 0 ) {
                        main.css('margin-top', 0);
                    }
                }
            }
        }
    }

    if(wrapNav.length) {
        if ( !isMobile ) {
            if ( $('#interactiveMaps').length == 0 ) {
                $(window).scroll(function() {
                    if( !$('#topNav').hasClass('navbar__top--hide') ) {
                        stickyHeader();
                    }
                });
            }
        } else {
            wrapNav.addClass('is-fixed');
    
            if ( $('#interactiveMaps').length == 0 ) {
    
                if (gr_submenu.length > 0) {
                    pageWrap.css('padding-top', wrapNavH + gr_submenu.outerHeight() + 10 + 'px');
                } else {
                    pageWrap.css('padding-top', wrapNavH);
                }
     
            }
        }
    }

    // Input File

    $('input[type="file"]').on('change', function() {
        var value = $(this).val().split('\\').pop();
        $(this).prev().val(value);
    });

    $('.input-file .input-group-btn').on('click', function() {
        $(this).prev().click();
    });
    

    // Calendar

    if ( $('#calendar').length ) {
        $('body').keyup(function(e) {
            if( e.which == 27 ) {
                $('#events-modal').modal('hide');
            }
        });
    }
    

    // Galleries

    $('#slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: false,
        centerMode: true,
        prevArrow: '<button type="button" class="gallery__arrow gallery__prev"></button>',
        nextArrow: '<button type="button" class="gallery__arrow gallery__next"></button>',
    });

    $('[notice-slider]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        speed: 400,
        autoplay: true,
        autoplaySpeed : 5000,
        prevArrow: '<button type="button" class="gallery__arrow gallery__prev"></button>',
        nextArrow: '<button type="button" class="gallery__arrow gallery__next"></button>',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    adaptiveHeight: true,
                },
            },
        ],        
    });

    // Address Form
    /*
    $('.address-find-civicno').mask('0#');
    */

    $.get('/addresses.json', function(response) {
        var addresses = window.adresses = response;

        $('.address-find-street').autocomplete({
            minLength: 3,
            source: function(request, response) {
                var term = request.term;
                var data = handleAutocomplete(addresses, term);
                response(data);
            },
            appendTo: '.address-ui',
            multiple: true,

            select: function() {
                $('.address-find-street').css('background-color', '');
                $('.form-address-find-error').slideUp();
            },
        });
    });

    var counter;

    function recursiveListUpdate(addresses, keywords) {
        var newList = [];
        var keyword = keywords[counter].toLowerCase();
        var apostropheKeyword = keyword.split('\'');
        var isApostropheKeyword = apostropheKeyword.length == 2;

        addresses.forEach(function(element) {
            var lowerCaseElement = element.toLowerCase();
            if (lowerCaseElement.indexOf(keyword) >= 0 ||
                (isApostropheKeyword && lowerCaseElement.indexOf(apostropheKeyword[0] + '\'') >= 0
                    && lowerCaseElement.indexOf(apostropheKeyword[1]) >= 0)) {
                newList.push(decodeURI(element));
            }
        });

        counter++;

        if (counter == keywords.length) {
            return newList;
        }

        return recursiveListUpdate(newList, keywords);
    }

    function handleAutocomplete(addresses, term) {
        var str = term;
        var keywordsFromNameInput = str.split(' ');
        counter = 0;

        return recursiveListUpdate(addresses, keywordsFromNameInput);
    }

    $('.address-find-street').on('keyup', function (e) {
        if (
            e.which !== 16 && // Shirt
            e.which !== 17 && // Ctrl
            e.which !== 35 && // End
            e.which !== 36 && // Home
            e.which !== 37 && // Left arrow
            e.which !== 39 // Right arrow
        ) {
            $(this).val(removeAccents($(this).val()));
        }
    });

    $('.address-find-street').on('keyup', function (e) {
        if (
            e.which !== 16 && // Shirt
            e.which !== 17 && // Ctrl
            e.which !== 35 && // End
            e.which !== 36 && // Home
            e.which !== 37 && // Left arrow
            e.which !== 39 // Right arrow
        ) {
            $(this).val(removeAccents($(this).val()));
        }
    });

    $('.form-address-find').on('submit', function (e) {
        var foundValue = false;
        $.each(window.adresses, function() {
            if (this === $('.address-find-street').val()) {
                foundValue = true;
            }
        });

        if (!foundValue) {
            e.preventDefault();
            $('.address-find-street').css('background-color', '#f1cfcf');
            $('.form-address-find-error').slideDown();
        }
    });


    var autocomplete = $('.autocomplete'),
        autocompleteSearchInput = $('.form-helpbar__search input');

    // Hide the search bar when clicked outside
    if (autocomplete && autocompleteSearchInput) {
        $(document).on('click', function () {
            $('.autocomplete').css('display', 'none');
            $(autocompleteSearchInput).css('border-bottom-left-radius', 30);
            $('.autocomplete').blur();
        });

        // prevent bubbling
        $(autocomplete).on('click', function (e) {
            e.stopPropagation();
        });
    }


    $('#helpBarForm [type="search"]').focus(function() {
        var inputPosition = $(this).offset().top - ($(window).height() / 2);
        $('html, body').stop().animate({
            scrollTop: inputPosition,
        }, 480);
    });

    // News Archives Year Selector
    $('.js-select-year').on('change', function() {
        var url = $(this).val();

        if (url !== '') {
            window.location.href = url;
        }
    });

})(jQuery); // End of use strict

function removeAccents (str) {

    var accentsMap = [
        {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
        {'base':'AA','letters':/[\uA732]/g},
        {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
        {'base':'AO','letters':/[\uA734]/g},
        {'base':'AU','letters':/[\uA736]/g},
        {'base':'AV','letters':/[\uA738\uA73A]/g},
        {'base':'AY','letters':/[\uA73C]/g},
        {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
        {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
        {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
        {'base':'OI','letters':/[\u01A2]/g},
        {'base':'OO','letters':/[\uA74E]/g},
        {'base':'OU','letters':/[\u0222]/g},
        {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
        {'base':'VY','letters':/[\uA760]/g},
        {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
        {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
        {'base':'aa','letters':/[\uA733]/g},
        {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
        {'base':'ao','letters':/[\uA735]/g},
        {'base':'au','letters':/[\uA737]/g},
        {'base':'av','letters':/[\uA739\uA73B]/g},
        {'base':'ay','letters':/[\uA73D]/g},
        {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
        {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
        {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
        {'base':'oi','letters':/[\u01A3]/g},
        {'base':'ou','letters':/[\u0223]/g},
        {'base':'oo','letters':/[\uA74F]/g},
        {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
        {'base':'vy','letters':/[\uA761]/g},
        {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
    ];

    for (var i = 0; i < accentsMap.length; i++) {
        str = str.replace(accentsMap[i].letters, accentsMap[i].base);
    }

    return str;
}

$(function () {

    var wysiwygTable = $('.wysiwyg table');

    if (wysiwygTable.length > 0) {
        wysiwygTable.wrap('<div class="wysiwyg-table"></div>');
    }
});