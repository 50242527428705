(function(){

    function is_phone() { return window.innerWidth < 640 ? true : false; }
   
    var target = document.querySelector('[gr-submenu]'),
        targetHeight,
        trigger = document.querySelector('[gr-submenu-trigger]'),
        navTop = document.querySelector('#nav'),
        isPhone = is_phone();

    if (trigger && target && isPhone) {

        targetHeight = target.offsetHeight;

        trigger.addEventListener('click', function (e) {
            e.preventDefault();

            var el = this;

            el.style.height = targetHeight + "px";

            target.classList.toggle('is-active');
        });  

        init();
        updatePos(0);

        window.addEventListener('scroll', function () {
            var windowTop = window.pageYOffset;

            if (windowTop > targetHeight) {
                updatePos(-100);
            } else {
                updatePos(0);
            }
        });           
    }

    function init() {

        // get alert if exist
        var pageWrap = $('#page');
        
        // Add the class to body to target the nav and change its transition preperties
        document.querySelector('body').classList.add('gr-submenu-scroll-active');

        // Set the gr-submenu min height to the top nav height
        target.style.minHeight = navTop.offsetHeight + 10 + "px";

        // Set the position top of the dropdown menu to the height of the topNav so its under.
        target.style.top = navTop.offsetHeight + 10 + "px";

        // Padding top for the main wrapper

        if (!$('body').hasClass('has-alert')) {
            pageWrap.css('padding-top', $(navTop).outerHeight() + $(target).outerHeight() + 10 + "px");
        }

    
    }

    function updatePos (nb) {
        target.style.transform = "translate3d(0," + nb + "%, 0)";
        topNav.style.transform = "translate3d(0," + nb + "%, 0)";
    }


}());



